<template>
  <span>
    <v-btn
      color="info"
      @click.stop="openExportDataDialog()"
      :disabled="disabled"
      :outlined="outlined"
    >
      <v-icon left dark> mdi-table-arrow-right </v-icon>
      {{ $t("btnExportData") }}
    </v-btn>
    <v-dialog
      v-model="exportDataDialog"
      transition="dialog-top-transition"
      :max-width="dialogWidth"
    >
      <v-card>
        <v-toolbar color="secondary" dark dense flat>
          <v-toolbar-title>{{
            $t("ExportData.title")
          }}</v-toolbar-title></v-toolbar
        >
        <v-divider></v-divider>
        <v-form ref="editSelectorForm">
          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12">
                  <v-select
                    v-model="datetimeFormatDefault.fval"
                    :items="datetimeFormat"
                    item-text="lbl"
                    item-value="fval"
                    :label="$t('ExportData.formatDatetime_label')"
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="decimalFormatDefault.fval"
                    :items="decimalFormat"
                    item-text="lbl"
                    item-value="fval"
                    :label="$t('ExportData.formatDecimal_label')"
                    dense
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <download-excel
              :fields="formatedFields"
              :fetch="fetchData"
              worksheet="txm_export"
              name="txm_export.xls"
              ><v-btn color="info" text>{{ $t("ExportData.btnExcel") }}</v-btn>
            </download-excel>
            <download-excel
              :fields="formatedFields"
              :fetch="fetchData"
              worksheet="txm_export"
              name="txm_export.csv"
              type="csv"
              ><v-btn color="info" text>{{ $t("ExportData.btnCsv") }}</v-btn>
            </download-excel>
            <download-excel
              :data="jsonData"
              worksheet="txm_export"
              name="txm_export.xls"
              ><v-btn color="grey" text>{{
                $t("ExportData.btnRawData")
              }}</v-btn>
            </download-excel>
            <v-btn color="error" text @click.stop="exportDataDialog = false">{{
              $t("btnClose")
            }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </span>
</template>

<script>
//import axios from "axios";
import * as moment from "moment/moment";
const DEFAULT_DIALOG_WIDTH = 460;
export default {
  name: "ExportData",
  props: {
    jsonData: { type: Array },
    jsonFields: { type: Object },
    filled: { type: Boolean },
    outlined: { type: Boolean },
    disabled: { type: Boolean },
    dense: { type: Boolean },
    medium: { type: Boolean },
    loading: { type: Boolean },
    label: { type: String, default: "Download" },
    title: { type: String, default: "Download" },
    dialogWidth: { type: Number, default: DEFAULT_DIALOG_WIDTH },
  },
  data() {
    return {
      moment: moment,
      exportDataDialog: false,
      formatedFields: {},
      datetimeFormatDefault: {
        lbl: "DD.MM.YYYY HH:mm:ss",
        fval: "DD.MM.YYYY HH:mm:ss",
      },
      datetimeFormat: [
        { lbl: "DD.MM.YYYY HH:mm:ss", fval: "DD.MM.YYYY HH:mm:ss" },
        { lbl: "DD.MM.YY HH:mm", fval: "DD.MM.YY HH:mm" },
        { lbl: "MM/DD/YYYY h:mm:ss A (AM/PM)", fval: "MM/DD/YYYY h:mm:ss A" },
        { lbl: "MM/DD/YY h:mm A (AM/PM)", fval: "MM/DD/YY h:mm A" },
        { lbl: "Localized", fval: "L LTS" },
        { lbl: "ISO", fval: "" },
      ],
      decimalFormatDefault: { lbl: "Čiarka", fval: "comma" },
      decimalFormat: [
        { lbl: "Čiarka", fval: "comma" },
        { lbl: "Bodka", fval: "dot" },
      ],
      //Standardny snackbar
      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 3000,
      },

      items: [],
      posts: [],
      errors: [],
    };
  },

  created() {},

  mounted() {},

  computed: {},

  watch: {},

  methods: {
    openExportDataDialog() {
      //Zobrazenie Dialogoveho okna s zoznamom moznosti na zmenu objednavky
      this.exportDataDialog = true;
    },
    fetchData() {
      var tempFields = this.jsonFields;
      var finalObject = {};
      console.log("datetimeFormatDefault", this.datetimeFormatDefault);
      console.log(
        "datetimeFormatDefault.fval",
        this.datetimeFormatDefault.fval
      );
      for (var key in tempFields) {
        if (tempFields.hasOwnProperty(key)) {
          var tempSubproperty = tempFields[key];
          //console.log("tempSubproperty", tempSubproperty);
          for (var subkey in tempSubproperty) {
            if (tempSubproperty.hasOwnProperty(subkey)) {
              //console.log("subkey", subkey);
              if (subkey == "type") {
                //tempFields[key].callbackx = tempSubproperty[subkey];
                if (tempSubproperty[subkey] == "datetime") {
                  //tempFields[key].callback = (value) => { return `${moment(value).format("L LTS")}`; };
                  tempFields[key].callback = (value) => {
                    //check if value can be converted to date
                    if (isNaN(Date.parse(value))) return value;
                    return `${moment(value).format(
                      this.datetimeFormatDefault.fval
                    )}`;
                  };
                  //tempFields[key]["callbackX"] = tempSubproperty[subkey];
                }
                if (tempSubproperty[subkey] == "decimal" || tempSubproperty[subkey] == "number") {
                  if (this.decimalFormatDefault.fval == "comma") {
                    tempFields[key].callback = (value) => {
                      value = value.toString();
                      return `${value.replace(/\./g, ",")}`;
                    };
                  } else if (this.decimalFormatDefault.fval == "dot") {
                    tempFields[key].callback = (value) => {
                      value = value.toString();
                      return `${value.replace(/\,/g, ".")}`;
                    };
                  } else {
                    tempFields[key].callback = (value) => {
                      value = value.toString();
                      return `${value.replace(/\,/g, ".")}`;
                    };
                  }
                  //tempFields[key]["callbackX"] = tempSubproperty[subkey];
                }
                if (tempSubproperty[subkey] == "string") {
                  tempFields[key].callback = (value) => {
                    //if number, covert to string
                    if (typeof value === "number")
                      value = "'" + value.toString();
                    //if starts with +, add '
                    if (value.startsWith("+")) value = "'" + value;
                    return `${value}`;
                  };
                }
              }
            }
          }

          finalObject[key] = tempFields[key];
        }
      }
      this.formatedFields = Object.assign({}, finalObject);
      console.log("fomatedFields", this.formatedFields);
      //return this.formatedFields;
      return this.jsonData;
    },

    swap(json) {
      var ret = {};
      for (var key in json) {
        ret[json[key]] = key;
      }
      return ret;
    },
  },
};
</script>
